@import '@angular/cdk/overlay-prebuilt.css';

html {
    min-height: 100%;
}

.overflow-scroll-y {
    overflow: hidden scroll;
}

// Overwrites for big-paragraphs-holder specific styles
big-paragraphs-holder big-paragraph-text:first-child {
    margin-top: 0 !important;
}
