@import '@angular/cdk/overlay-prebuilt.css';
html {
  min-height: 100%;
}

.overflow-scroll-y {
  overflow: hidden scroll;
}

big-paragraphs-holder big-paragraph-text:first-child {
  margin-top: 0 !important;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL2J1aWxkcy9VeGtSUHU0VW0vMC93ZWItcG9ydGFsL25nLWJpZy1mcm9udGVuZC9hcHBzL2JpZy1lbWJlZGRlZC9zcmMvc3R5bGVzLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQVE7QUFFUjtFQUNJOzs7QUFHSjtFQUNJOzs7QUFJSjtFQUNJIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCAnQGFuZ3VsYXIvY2RrL292ZXJsYXktcHJlYnVpbHQuY3NzJztcblxuaHRtbCB7XG4gICAgbWluLWhlaWdodDogMTAwJTtcbn1cblxuLm92ZXJmbG93LXNjcm9sbC15IHtcbiAgICBvdmVyZmxvdzogaGlkZGVuIHNjcm9sbDtcbn1cblxuLy8gT3ZlcndyaXRlcyBmb3IgYmlnLXBhcmFncmFwaHMtaG9sZGVyIHNwZWNpZmljIHN0eWxlc1xuYmlnLXBhcmFncmFwaHMtaG9sZGVyIGJpZy1wYXJhZ3JhcGgtdGV4dDpmaXJzdC1jaGlsZCB7XG4gICAgbWFyZ2luLXRvcDogMCAhaW1wb3J0YW50O1xufVxuIl19 */